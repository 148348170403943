import { createSlice } from '@reduxjs/toolkit';

const languageSlice = createSlice({
    name: 'language',
    initialState: localStorage.lang || 'en-US',
    reducers: {
        setLanguage: (state, action) => action.payload,
    }
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
